import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/";
import DefaultLayout from "@/layouts/Default";
import BareLayout from "@/layouts/Bare";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import firebase from "@firebase/app";
import Toasted from "vue-toasted";
import AsyncComputed from "vue-async-computed";
import ImageUploader from "vue-image-upload-resize";
import "./registerServiceWorker";
import "@firebase/auth";
import "@/firebase";

Vue.config.productionTip = false;

Vue.use(Toasted);

// Init MomentJS for timestamps
const moment = require("moment");
require("moment/locale/de");
require("moment-recur");
Vue.use(require("vue-moment"), {
  moment,
});

Vue.use(ImageUploader);

// Init Async computed
Vue.use(AsyncComputed);

// Import layouts
Vue.component("default-layout", DefaultLayout);
Vue.component("bare-layout", BareLayout);

require("@/assets/css/style.css");
require("@/assets/css/responsive.css");

// Init Sentry
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DNS,
    release: process.env.VUE_APP_RELEASE,
    environment: "production",
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  });
}

// Initialize Vue
let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
