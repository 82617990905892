<template>
  <div id="app">
    <div class="main">
      <component :is="layout">
        <router-view />
      </component>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { EventBus } from "@/event-bus";

const defaultLayout = "default";

export default {
  name: "App",
  data() {
    return {
      eventListenersAttached: false,
    };
  },
  beforeCreate() {
    this.$store
      .dispatch("user/setRef")
      .then(() => this.$store.dispatch("post/setRef"))
      .then(() => this.$store.dispatch("event/setRef"))
      .then(() => this.$store.dispatch("user/setStorageReady"));
  },
  created() {
    this.addEventListeners();

    if ("serviceWorker" in navigator) {
      // eslint-disable-next-line
      if ('BroadcastChannel' in self) {
        // eslint-disable-next-line no-undef
        const swListener = new BroadcastChannel("swListener");
        swListener.onmessage = (event) => {
          if (event.data && event.data.cmd === "installed") {
            this.showNewVersionAvailableToast();
          }
        };
      }
    } else {
      // Wait for update message by service worker for new version
      self.addEventListener("message", (evt) => {
        if (evt.data && evt.data.cmd === "installed") {
          this.showNewVersionAvailableToast();
        }
      });
    }
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
  methods: {
    addEventListeners() {
      if (this.eventListenersAttached) {
        return;
      }
      EventBus.$on("appUpdated", () => this.showNewVersionAvailableToast());
      EventBus.$on("saved", (message) => this.showSavedToast(message));
      this.eventListenersAttached = true;
    },
    showNewVersionAvailableToast() {
      Vue.toasted.show(
        "Eine neue Version vom SKÖ-Backend wurde installiert 🚀",
        {
          theme: "primary",
          position: "bottom-center",
          duration: null,
          fullWidth: true,
          className: "toast-update",
          action: {
            text: "Aktualisieren",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
              window.location.reload();
            },
          },
        }
      );
    },
    showSavedToast(message) {
      Vue.toasted.show(message, {
        theme: "primary",
        position: "bottom-right",
        duration: 2500,
        fullWidth: false,
        type: "success",
      });
    },
  },
};
</script>

<style>
:root {
  --blue: #2c3e50;
  --orange: #f05f40;
  --green: #42b983;
  --light-gray: #777772;
  --red: #d0021b;
}

#app,
body,
input,
textarea {
  font-family: "Libre Franklin", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: rgba(69, 126, 184, 0.05);
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.main {
  padding-top: 100px;
}
.meta {
  color: var(--light-gray);
}

.toasted.primary {
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 0.28571429rem !important;
  background: var(--green);
  font-weight: 600;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}
.toasted.primary.action {
  border-radius: 4px;
  background: var(--green);
}
.toast-update.primary.default {
  color: var(--blue);
  border-radius: 4px;
  background: white;
}
.toast-update.primary.default a {
  color: #4183c4;
}
</style>
