import firebase from "@firebase/app";
import store from "@/store/";
import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";
import "@firebase/auth";
firebase.auth().languageCode = "de";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  current: null,
};

/**
 * Getters
 */
export const getters = {
  current: (state) => state.current,
};

/**
 * Actions
 */
export const actions = {
  setStorageReady() {
    store._vm.$root.$data["vue-ready-delay"] = false;
    store._vm.$root.$emit("storageReady");
  },

  /**
   * Receives a user from Firestore
   */
  async getById(_, { uid }) {
    return new Promise((resolve, reject) => {
      var docRef = db.collection("users").doc(uid);

      docRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            reject({
              code: "auth/user-not-found",
              message: "User has not been found",
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Add subscription for data from Firebase
   */
  setRef: firestoreAction(async ({ bindFirestoreRef }) => {
    const user = firebase.auth().currentUser;
    if (!user) {
      return null;
    }

    bindFirestoreRef("current", db.collection("users").doc(user.uid), {
      reset: () => state.current,
    }).catch((error) => console.error("Error receiving admin user", error));
  }),

  /**
   * Signs in existing email user with Firebase
   * @param {*} param0
   * @param {*} param1
   */
  loginAdminWithEmail({ dispatch }, { email, password }) {
    return Promise.resolve().then(() =>
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((response) => dispatch("getById", { uid: response.user.uid }))
        .then(async (user) => {
          if (!user) {
            await dispatch("logout");
            throw {
              code: "auth/user-not-found",
              message: "User has not been found",
            };
          }
          // Check if user is manager or admin
          const isPowerUser =
            user.roles && (user.roles.manager || user.roles.admin);
          if (!isPowerUser) {
            await dispatch("logout");
            throw {
              code: "auth/no-permission",
              message: "User has no permission to access backend",
            };
          } else {
            return dispatch("setRef");
          }
        })
    );
  },

  /**
   * Sends a password recovery email to the corresponding user
   * @param {*} param0
   * @param {*} param1
   */
  sendAdminRecoverPasswordEmail({ commit }, { email }) {
    var actionCodeSettings = {
      url: `https://backend.skiklub-oestertal.de/anmelden?email=${email}`,
    };

    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, actionCodeSettings)
        .then(() => resolve(commit("resetAdminUser")))
        .catch((error) => reject(error));
    });
  },

  updateAdminProfile: firestoreAction((_, { user, property, value }) => {
    return new Promise((resolve, reject) => {
      var localUser = firebase.auth().currentUser;
      if (localUser.uid != user.uid) {
        throw new Error("User not allowed to update profile");
      }

      const update = {};
      update[`${property}`] = value ? value : false;
      update["updated"] = firebase.firestore.FieldValue.serverTimestamp();

      return db
        .collection("users")
        .doc(user.uid)
        .update(update)
        .then(() => resolve())
        .catch((error) => {
          console.error("Error updating admin profile", error);
          reject(error);
        });
    });
  }),

  /**
   * Logs out the current user from Firebase
   * @param {*} param0
   */
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => resolve(commit("resetAdminUser")))
        .catch((err) => reject(err));
    });
  },
};

/**
 * Mutations
 */
export const mutations = {
  /**
   * Sets the current firebase user
   * @param {*} state
   * @param {*} user
   */
  setAdminUser(state, user) {
    state.current = {
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      id: user.uid,
    };
  },

  /**
   * Resets the current user
   * @param {*} state
   */
  resetAdminUser(state) {
    state.current = null;
  },
};
