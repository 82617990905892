export default function vuexReadyPatch() {
  return (store) => {
    store._vm.$root.$data["vue-ready-delay"] = true;

    store.subscribe((mutation) => {
      if (mutation.type === "vuexfire/SET_VALUE") {
        store._vm.$root.$data["vue-ready-delay"] = false;
        store._vm.$root.$emit("storageReady");
      }
    });
  };
}
