import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";
import firebase from "@firebase/app";
import { EventBus } from "@/event-bus";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  list: [],
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.list,
  drafts: (state) => {
    return state.list.filter((post) => {
      return post.visibility === "draft";
    });
  },
  postBySlug: (state) => (slug) => {
    return state.list.find((post) => {
      return post.slug === slug;
    });
  },
  postById: (state) => (id) => {
    return state.list.find((post) => {
      return post.id === id;
    });
  },
  relatedPostsBySlug: (state) => (slug) => {
    return state.list.filter((post) => {
      return post.slug !== slug;
    });
  },
};

/**
 * Actions
 */
export const actions = {
  setRef: firestoreAction(async ({ bindFirestoreRef }) => {
    bindFirestoreRef(
      "list",
      db.collection("posts").orderBy("created", "desc").limit(20)
    );
  }),
  create: firestoreAction((_, { payload }) => {
    const insert = payload;
    insert["updated"] = firebase.firestore.FieldValue.serverTimestamp();
    insert["created"] = firebase.firestore.FieldValue.serverTimestamp();
    if (payload.content) {
      insert["excerpt"] = payload.content.substr(0, 250);
    }
    if (payload.title) {
      insert["slug"] = slugify(payload.title);
    }

    return db
      .collection("posts")
      .add(insert)
      .then((result) => {
        EventBus.$emit("saved", "Beitrag hinzugefügt");
        return result;
      })
      .catch((error) => console.error("Error inserting post", error));
  }),
  update: firestoreAction((_, { postId, payload }) => {
    const update = payload;
    update["updated"] = firebase.firestore.FieldValue.serverTimestamp();
    update["created"] = new firebase.firestore.Timestamp.fromDate(
      payload.created.toDate()
    );
    if (payload.content) {
      update["excerpt"] = payload.content.substr(0, 250);
    }
    if (payload.title) {
      update["slug"] = slugify(payload.title);
    }

    return db
      .collection("posts")
      .doc(postId)
      .update(update)
      .then(() => EventBus.$emit("saved", "Beitrag gespeichert"))
      .catch((error) => console.error("Error updating post", error));
  }),
  delete: firestoreAction((_, { postId }) => {
    return db
      .collection("posts")
      .doc(postId)
      .delete()
      .then(() => EventBus.$emit("saved", "Beitrag gelöscht"))
      .catch((error) => console.error("Error deleting post", error));
  }),
};

/**
 * Mutations
 */
export const mutations = {};

/* eslint-disable */
function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}
/* eslint-enable */
