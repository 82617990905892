<template>
  <nav class="ui fixed top menu">
    <div class="header item">
      <img src="@/assets/images/logo.png" />
    </div>
    <router-link class="item" :to="{ name: 'posts' }"> Beiträge </router-link>
    <router-link class="item" :to="{ name: 'events' }"> Termine </router-link>
    <router-link class="item" :to="{ name: 'courses' }"> Kurse </router-link>
    <!--
    <router-link class="item" :to="{ name: 'pages' }">
      Seiten
    </router-link>
    -->
    <div class="right icon menu">
      <div class="ui dropdown item">
        <i class="user circle large icon"></i>
        <div class="menu">
          <a class="item" @click="logout">Abmelden</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  mounted() {
    $(".ui.dropdown").dropdown();
  },
  methods: {
    logout() {
      const self = this;
      this.$store
        .dispatch("user/logout")
        .then(() => self.$router.replace({ name: "login" }));
    },
  },
};
</script>
