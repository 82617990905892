import { EventBus } from "@/event-bus";
import { db } from "@/firebase";
import firebase from "@firebase/app";
import orderBy from "lodash/orderBy";
import Vue from "vue";
import { firestoreAction } from "vuexfire";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  list: [],
};

/**
 * Getters
 */
export const getters = {
  list: (state) => orderBy(state.list, ["startAt"], ["desc"]),
  recurring: (state) => {
    const list = state.list.filter((course) => {
      return course.isRecurring === true;
    });
    return orderBy(list, ["title"], ["asc"]);
  },
  once: (state) => {
    const list = state.list.filter((course) => {
      return course.isRecurring !== true;
    });
    return orderBy(list, ["startAt.seconds"], ["desc"]);
  },
  drafts: (state) => {
    return state.list.filter((course) => {
      return course.visibility === "draft";
    });
  },
  courseBySlug: (state) => (slug) => {
    return state.list.find((course) => {
      return course.slug === slug;
    });
  },
  eventById: (state) => (id) => {
    return state.list.find((event) => {
      return event.id === id;
    });
  },
};

/**
 * Actions
 */
export const actions = {
  setRef: firestoreAction(async ({ bindFirestoreRef }) => {
    await bindFirestoreRef(
      "list",
      db.collection("events").orderBy("startAt", "asc").limit(100)
    );
  }),
  create: firestoreAction((_, { payload }) => {
    const insert = payload;
    insert["updated"] = firebase.firestore.FieldValue.serverTimestamp();
    insert["created"] = firebase.firestore.FieldValue.serverTimestamp();
    if (payload.startAt) {
      insert["startAt"] = new firebase.firestore.Timestamp.fromDate(
        payload.startAt.toDate()
      );
    }
    if (payload.endAt) {
      insert["endAt"] = new firebase.firestore.Timestamp.fromDate(
        payload.endAt.toDate()
      );
    }
    if (payload.title) {
      insert["slug"] = slugify(payload.title);
    }

    return db
      .collection("events")
      .add(insert)
      .then((result) => {
        EventBus.$emit("saved", "Termin hinzugefügt");
        return result;
      })
      .catch((error) => console.error("Error inserting course", error));
  }),
  async cancelCourseEvent({ dispatch }, { event, timestamp, reason }) {
    return Promise.resolve().then(() => {
      const update = {};
      update["updated"] = firebase.firestore.FieldValue.serverTimestamp();

      // Check if date is already cancelled, add date if not
      let isEventAlreadyCancelled = false;
      for (const key in event.cancelled) {
        if (key == Vue.moment(timestamp).toISOString()) {
          isEventAlreadyCancelled = true;
        }
      }
      if (isEventAlreadyCancelled) {
        throw Error("Event is already cancelled");
      }

      // Update document
      let cancelledEvents = event.cancelled ? event.cancelled : {};
      cancelledEvents[`${Vue.moment(timestamp).toISOString()}`] = reason;
      update["cancelled"] = cancelledEvents;

      return dispatch("update", { eventId: event.id, payload: update });
    });
  },
  async revertCancelCourseEvent({ dispatch }, { event, timestamp }) {
    return Promise.resolve().then(() => {
      const update = {};
      update["updated"] = firebase.firestore.FieldValue.serverTimestamp();

      // Remove time element from timestamp
      const cleanedTimestamp = timestamp.startOf("day");

      // Check if date is already deleted, add date if not
      if (!event.cancelled || event.cancelled.length === 0) {
        throw Error("Event has not been cancelled before");
      }
      let isEventAlreadyCancelled = false;
      for (const key in event.cancelled) {
        if (key == Vue.moment(cleanedTimestamp).toISOString()) {
          isEventAlreadyCancelled = true;
        }
      }
      if (!isEventAlreadyCancelled) {
        throw Error("Event has not been cancelled before");
      }

      // Update document
      let cancelledEvents = event.cancelled ? event.cancelled : {};
      delete cancelledEvents[Vue.moment(timestamp).toISOString()];
      update["cancelled"] = cancelledEvents;

      return dispatch("update", { eventId: event.id, payload: update });
    });
  },
  async deleteCourseEvent({ dispatch }, { event, timestamp }) {
    return Promise.resolve().then(() => {
      const update = {};
      update["updated"] = firebase.firestore.FieldValue.serverTimestamp();

      // Remove time element from timestamp
      const cleanedTimestamp = timestamp.startOf("day");

      // Check if date is already deleted, add date if not
      if (event.exceptions) {
        const exception = event.exceptions.find(
          (exception) =>
            Vue.moment(exception).toISOString() ==
            Vue.moment(cleanedTimestamp).toISOString()
        );
        if (exception) {
          throw Error("Event has been deleted before");
        }
      }

      // Update document
      let deletedEvents = event.exceptions ? event.exceptions : [];
      deletedEvents.push(Vue.moment(cleanedTimestamp).toISOString());
      update["exceptions"] = deletedEvents;

      return dispatch("update", { eventId: event.id, payload: update });
    });
  },
  async revertDeleteCourseEvent({ dispatch }, { event, timestamp }) {
    return Promise.resolve().then(() => {
      const update = {};
      update["updated"] = firebase.firestore.FieldValue.serverTimestamp();

      // Remove time element from timestamp
      const cleanedTimestamp = timestamp.startOf("day");

      // Check if date is already deleted, add date if not
      if (!event.exceptions || event.exceptions.length === 0) {
        throw Error("Event has not been deleted before");
      }
      const exception = event.exceptions.find(
        (exception) =>
          Vue.moment(exception).toISOString() ==
          Vue.moment(cleanedTimestamp).toISOString()
      );
      if (!exception) {
        throw Error("Event has not been deleted before");
      }

      // Update document
      let deletedEvents = event.exceptions ? event.exceptions : [];
      update["exceptions"] = deletedEvents.filter(
        (exception) =>
          Vue.moment(exception).toISOString() !=
          Vue.moment(cleanedTimestamp).toISOString()
      );

      return dispatch("update", { eventId: event.id, payload: update });
    });
  },
  update: firestoreAction((_, { eventId, payload }) => {
    const update = payload;
    update["updated"] = firebase.firestore.FieldValue.serverTimestamp();
    if (payload.startAt) {
      update["startAt"] = new firebase.firestore.Timestamp.fromDate(
        payload.startAt.toDate()
      );
    }
    if (payload.endAt) {
      update["endAt"] = new firebase.firestore.Timestamp.fromDate(
        payload.endAt.toDate()
      );
    }
    if (payload.excerpt) {
      update["excerpt"] = payload.excerpt.substr(0, 250);
    }
    if (payload.title) {
      update["slug"] = slugify(payload.title);
    }

    return db
      .collection("events")
      .doc(eventId)
      .update(update)
      .then(() => EventBus.$emit("saved", "Änderungen gespeichert"))
      .catch((error) => console.error("Error updating course", error));
  }),
  delete: firestoreAction((_, { eventId }) => {
    return db
      .collection("events")
      .doc(eventId)
      .delete()
      .then(() => EventBus.$emit("saved", "Termin gelöscht"))
      .catch((error) => console.error("Error deleting course", error));
  }),
};

/**
 * Mutations
 */
export const mutations = {};

/* eslint-disable */
function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}
/* eslint-enable */
