<template>
  <div>
    <NavBar />
    <!-- [START] content -->
    <div class="section-content" id="content">
      <div class="container-content">
        <slot />
      </div>
    </div>
    <!-- [END] content -->
    <FooterHome />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import FooterHome from "@/components/Footer";

export default {
  name: "defaultTemplate",
  components: {
    NavBar,
    FooterHome,
  },
};
</script>
