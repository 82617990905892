import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";

import VuexReadyPatch from "@/store/plugins/vuex-ready-patch";

// import * as event from "@/store/modules/event";
import * as user from "@/store/modules/user";
import * as post from "@/store/modules/post";
import * as event from "@/store/modules/event";
import * as image from "@/store/modules/image";
import * as notification from "@/store/modules/notification";

// import * as subscription from "@/store/modules/subscription";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  mutations: {
    ...vuexfireMutations,
  },
  modules: {
    user,
    post,
    event,
    image,
    notification,
  },
  plugins: [VuexReadyPatch()],
});
