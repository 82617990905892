import Vue from "vue";
import Router from "vue-router";
import store from "@/store/";
import firebase from "@firebase/app";
import "@firebase/auth";

Vue.use(Router);

const scrollBehavior = () => {
  return {
    x: 0,
    y: 0,
  };
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior,
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: "/neuigkeiten",
    },
    {
      path: "/neuigkeiten",
      name: "posts",
      component: () =>
        import(/* webpackChunkName: "post" */ "./pages/Posts.vue"),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/neuigkeiten/:id",
      name: "post",
      component: () =>
        import(/* webpackChunkName: "post" */ "./pages/Post.vue"),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/neuigkeiten/neuigkeit-erstellen",
      name: "newPost",
      component: () =>
        import(/* webpackChunkName: "post" */ "./pages/Post.vue"),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/kurse",
      name: "courses",
      component: () =>
        import(/* webpackChunkName: "course" */ "./pages/Courses.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/kurse/:id",
      name: "course",
      component: () =>
        import(/* webpackChunkName: "course" */ "./pages/Course.vue"),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/kurse/erstellen",
      name: "newCourse",
      component: () =>
        import(/* webpackChunkName: "course" */ "./pages/Course.vue"),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/termine",
      name: "events",
      component: () =>
        import(/* webpackChunkName: "event" */ "./pages/Events.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/termine/:id",
      name: "event",
      component: () =>
        import(/* webpackChunkName: "event" */ "./pages/Event.vue"),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/termine/erstellen",
      name: "newEvent",
      component: () =>
        import(/* webpackChunkName: "event" */ "./pages/Event.vue"),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/seiten",
      name: "pages",
      component: () =>
        import(/* webpackChunkName: "page" */ "./pages/Pages.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/anmelden",
      name: "login",
      meta: {
        layout: "bare",
        requiresGuest: true,
      },
      component: () =>
        import(/* webpackChunkName: "login" */ "./pages/auth/Login.vue"),
    },
    {
      path: "/passwort-zurücksetzen",
      name: "recover-password",
      meta: {
        requiresGuest: true,
        layout: "bare",
      },
      component: () =>
        import(
          /* webpackChunkName: "recover" */ "./pages/auth/RecoverPassword.vue"
        ),
    },
    {
      path: "/401",
      name: "401",
      component: () => import(/* webpackChunkName: "401" */ "./pages/401.vue"),
    },
  ],
});

// Protect routes from unauthorized access
router.beforeEach(async (to, from, next) => {
  if (store._vm.$root.$data["vue-ready-delay"]) {
    // eslint-disable-next-line
    store._vm.$root.$on("storageReady", () => {
      next({
        path: to.path,
        query: to.query,
        params: to.params,
      });
    });
  } else if (
    to.matched.some((record) => record.meta.requiresAuth) ||
    to.matched.some((record) => record.meta.requiresGuest)
  ) {
    const currentUser = firebase.auth().currentUser;
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresGuest = to.matched.some(
      (record) => record.meta.requiresGuest
    );

    if (requiresAuth && !currentUser) {
      next({ name: "login" });
    } else if (requiresGuest && currentUser) {
      next({ name: "posts" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
