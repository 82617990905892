import firebase from "firebase";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  list: [],
};

/**
 * Getters
 */
export const getters = {};

/**
 * Actions
 */
export const actions = {
  async upload(_, payload) {
    var storageRef = firebase.storage().ref();
    var imageRef = storageRef.child(`assets/${payload.fileName}`);
    return imageRef
      .putString(payload.fileDataUrl, "data_url")
      .then((snapshot) => snapshot.ref.getDownloadURL().then((url) => url));
  },
};

/**
 * Mutations
 */
export const mutations = {};
