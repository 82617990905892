import firebase from "@firebase/app";
import { firestoreAction } from "vuexfire";
import { EventBus } from "@/event-bus";
import { db } from "@/firebase";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {};

/**
 * Getters
 */
export const getters = {};

/**
 * Actions
 */
export const actions = {
  create: firestoreAction((_, { type, event, payload }) => {
    const insert = payload;
    insert["created"] = firebase.firestore.FieldValue.serverTimestamp();
    if (payload.email) insert["email"] = payload.email;
    if (payload.emailContent) insert["emailContent"] = payload.emailContent;
    // if (payload.sms) insert["sms"] = payload.sms;
    // if (payload.smsContent) insert["smsContent"] = payload.smsContent;
    insert["event"] = db.doc(`/events/${event.id}`);
    insert["eventId"] = event.id;
    insert["status"] = "pending";
    insert["type"] = type;

    return db
      .collection("notifications")
      .add(insert)
      .then((result) => {
        EventBus.$emit("saved", "Benachrichtigung hinzugefügt");
        return result;
      })
      .catch((error) => console.error("Error inserting notification", error));
  }),
};

/**
 * Mutations
 */
export const mutations = {};
